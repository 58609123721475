import React, { useState, useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import { navigate } from "gatsby-plugin-intl/link";
import queryString from 'query-string'
import SEO from "../components/seo"
import Form from '../components/hoc/Form/Form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import resetPassword from '../state/async/authResetPassword';
import {formError, getErrors, passwordReset, passwordResetting} from '../state/reducers/auth';


const ResetPasswordConfirm = ({location, formError, getErrors, resetPassword, passwordReset, passwordResetting}) => {
  const i18n = useIntl().formatMessage
  
  const [token, setToken] = useState('')

  useEffect(() => {
    setToken(queryString.parse(location.search)['token'])
  }, [])

  useEffect(() => {
    if(passwordReset) {
      navigate('/login')
    }
  }, [passwordReset])


  const submitHandler = async (sub) => {

    // emailPasswordSend && !formError && setSubmitted(true)
    await resetPassword(sub.password, token)

  }

  return (
    <>
      <SEO title="Reset password" />
      <section className="section-padding-big">
        <div className="container">
          <div className="row center-xs">
            <div className="col col-xs-12 col-md-6">
              <div className="card">
                <h1 className="h3">Reset Password</h1>
                {token ?
                  <Form 
                    submitHandler={submitHandler}
                    loading={passwordResetting}
                    success={passwordReset}
                    submitText={ `${i18n({ id: "general.next" })}  →`}
                    formElements={[
                        { type: 'password', name: 'password', label: i18n({ id: "general.new_password" }), value: '', required: true },
                        { type: 'password', name: 'password_repeat', label: i18n({ id: "general.new_repeat_password" }), required: true, value: '', errorText: i18n({ id: "general.validations.repeat_password" }) }
                    ]}
                    formError={formError}sa
                  />
                  
                : <p>Følg linket du har fået tilsendt på mail for at resette dit password.</p>
                }
                

            </div>
            </div>
          </div>
        </div>
      </section>
    </>
    )
}


const mapStateToProps = state => ({
  error: getErrors(state.auth),
  passwordReset: passwordReset(state.auth),
  passwordResetting: passwordResetting(state.auth),
  formError: formError(state.auth)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPassword: resetPassword,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordConfirm);
