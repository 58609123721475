import {passwordResetRequest, passwordResetSuccess, passwordResetFailure} from '../actions/auth';
const axios = require('axios');

function resetPasswordConfirm(password, token) {

    return dispatch => {

        dispatch(passwordResetRequest());
        console.log('dispatching passwordResetRequest')
        axios({
            method: 'post',
            url: `${process.env.GATSBY_API_ENDPOINT}/api/password_reset/confirm/`,
            data: {
                password,
                token
            },
        })
        .then((response) => {
            if(response.error) {
                console.log('passwordResetSuccess: throwing error: ', response.error.status)
                throw(response.error);
            }

            dispatch(passwordResetSuccess());
            return true;
        })
        .catch((err) => {
            console.log('passwordResetFailure', err.response.status)
            dispatch(
                passwordResetFailure(
                    err.response && err.response.data &&  err.response.data.status
                    || err.response.status)
            )
        })
    }

}

export default resetPasswordConfirm;